import { RouterProvider } from "react-router-dom";
import TanstackQueryProvider from "./providers/tanstack-query-provider";
import { router } from "./routes";
import MetaPixel from "./components/MetaPixel";
// import LogRocket from "logrocket";
import Plausible from "./components/Plausible";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import { Toaster } from "sonner";
import { GTM_IDS } from "./lib/config";
import GoogleTagManager from "./components/GoogleTagManager";
import isDev from "./lib/DevDetect";
import { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import PostHogPageviewTracker from "./components/PostHogPageviewTracker";

// if (process.env.NODE_ENV === "production")
// 	LogRocket.init("mjknmk/daily-sale-shop");

const MAGIC_STRINGS = {
	userLocation: "DSActualUserLocation",
};

const isProduction = !isDev();

if (isProduction) {
	posthog.init("phc_wrZr5P8OyRr0TSVpNcUdDSNDQKUQEEOrZI6i3Aqo0Kr", {
		api_host: "https://us.i.posthog.com",
		person_profiles: "always",
		capture_pageview: false,
	});
}

function App() {
	const [canAccess, setCanAccess] = useState<boolean>(true);
	const [isLoading, setLoading] = useState<boolean>(false);

	// const isProduction = process.env.NODE_ENV === "production";

	const domain = window.location.hostname as keyof typeof GTM_IDS;

	// if not in Cape Town or an error occurred, users should be able to access
	const fetchUserLocation = async () => {
		try {
			setLoading(true);
			const response = await fetch("https://ipapi.co/json/");
			const data = await response.json();

			if (data?.city && data?.country) {
				// Save location and current date to localStorage
				const locationData = {
					city: data.city,
					country: data.country,
					dateCaptured: new Date().toISOString(),
				};
				localStorage.setItem(
					MAGIC_STRINGS.userLocation,
					JSON.stringify(locationData)
				);
				setLoading(false);

				return data?.city === "Cape Town" && data?.country === "ZA"
					? false
					: true;
				// return data?.country === "ZA" ? false : true;
			} else {
				return true;
			}
		} catch (error) {
			return true;
		}
	};

	const checkLocation = async () => {
		const savedLocation = localStorage.getItem(MAGIC_STRINGS.userLocation);

		if (savedLocation) {
			const { city, country, dateCaptured } = JSON.parse(savedLocation);

			const capturedDate: any = new Date(dateCaptured);
			const currentDate: any = new Date();
			const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

			// Check if the data is older than one week
			if (currentDate - capturedDate < oneWeekInMilliseconds) {
				setCanAccess(city !== "Cape Town" && country !== "ZA");
				// setCanAccess(country !== "ZA");
				return;
			}
		}

		// Re-fetch the location if not available or older than a week
		const isCapeTown = await fetchUserLocation();
		setCanAccess(isCapeTown);
	};

	// useEffect(() => {
	// 	checkLocation();
	// }, []);

	return canAccess ? (
		<CacheBuster
			currentVersion={version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={null} //If not pass, nothing appears at the time of new version check.
			metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
		>
			<TanstackQueryProvider>
				{isProduction && <GoogleTagManager gtmId={GTM_IDS[domain]} />}
				<MetaPixel />
				{/* <Plausible /> */}
				{/* here we add more providers, like global modals, auth etc */}
				<PostHogProvider client={isProduction ? posthog : null}>
					<RouterProvider router={router} />
				</PostHogProvider>
				<Toaster
					position="bottom-right"
					expand={true}
					richColors
					closeButton
				/>
			</TanstackQueryProvider>
		</CacheBuster>
	) : (
		<div className="flex w-full h-screen justify-center items-center">
			{isLoading ? <PulseLoader /> : "An Error Occurred."}
		</div>
	);
}

export default App;
