import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";

// import * as Sentry from "@sentry/react";

// Sentry.init({
// 	dsn: "https://a9939a50faa6e5a7455adcda80cf82be@o4507176467103744.ingest.us.sentry.io/4507176953118720",
// 	integrations: [
// 		Sentry.browserTracingIntegration(),
// 		Sentry.replayIntegration({
// 			maskAllText: false,
// 			blockAllMedia: false,
// 		}),
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, //  Capture 100% of the transactions
// 	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 	tracePropagationTargets: ["localhost"],
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.createRoot(document.getElementById("root")!).render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);
