import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";

const BrandSlider = () => {
	return (
		<div className="bg-white py-2">
			<h3 className="font-semibold">Our Trusted Brands</h3>
			{/*  */}
			<Carousel className="">
				<CarouselContent>
					{[
						"/footer-logos/Hisense-logo_1.webp",
						"/footer-logos/5e85e78bc5ded767305fefadbfdce2d5b716ca91.webp",
						"/footer-logos/Computers_Brand4.webp",
						"/footer-logos/398bc48815c135c0f074174a2a5d4acdb36534ea_dRbMDMz.webp",
						"/footer-logos/6e71171c2c27d20ba1d09e7bb5cf8a07553057bd.webp",
						"/footer-logos/dae3f543ecf7633ec037b9339130872902bb2ee5.webp",
					].map((brand, key) => (
						<CarouselItem
							key={key}
							className="md:basis-1/2 lg:basis-1/5 flex items-center select-none"
						>
							<img
								src={brand}
								alt={"No Image Found"}
								// className="md:h-16 lg:h-32"
								className="select-none"
							/>
						</CarouselItem>
					))}
				</CarouselContent>
				<CarouselPrevious className="ml-3" />
				<CarouselNext className="mr-3" />
			</Carousel>
			{/*  */}
		</div>
	);
};

export default BrandSlider;
