import React from "react";
import { Navigate } from "react-router-dom";
// import useAuth from './hooks/useAuth'
import PulseLoader from "react-spinners/PulseLoader";

const isLoggedIn = false;
type AuthRouteProps = {
	lazy: () => Promise<{ default: React.ComponentType<any> }>;
	guestOnly?: boolean;
};

const AuthRoute: React.FC<AuthRouteProps> = ({
	lazy: getComponent,
	guestOnly,
}) => {
	//   const { isLoggedIn } = useAuth()

	const LazyComponent = React.lazy(() => getComponent());

	if (guestOnly && isLoggedIn) {
		return <Navigate to="/" replace />;
	} else if (!guestOnly && !isLoggedIn) {
		return <Navigate to="/sign-in" replace />;
	} else {
		return (
			<React.Suspense
				fallback={
					<div className="flex h-screen w-full items-center justify-center bg-white">
						<PulseLoader />
					</div>
				}
			>
				<LazyComponent />
			</React.Suspense>
		);
	}
};

export default AuthRoute;
