import { create } from "zustand";
import { persist } from "zustand/middleware";

/**
 * to add a decoded token value..
 * this can also be implemeted as a separate hook for the user profile.
 * we can only set user id, email and role or permissions in here...
 * */
interface IAuth {
	accessToken: string | null;
	refreshToken: string | null;
	isLoggedIn: boolean;
	userId: string | null;
	setCredentials: (tokens: {
		userId: string | null;
		accessToken: string | null;
		refreshToken: string | null;
	}) => void;
	clearCredentials: () => void; // Add logout feature
}

const useAuth = create<IAuth>()(
	persist(
		(set) => ({
			accessToken: null,
			refreshToken: null,
			isLoggedIn: false,
			userId: null,
			setCredentials: (tokens: {
				userId: string | null;
				accessToken: string | null;
				refreshToken: string | null;
			}) =>
				set((state) => ({
					...state,
					isLoggedIn: !!tokens.accessToken,
					userId: tokens.userId,
					accessToken: tokens.accessToken,
					refreshToken: tokens.refreshToken,
				})),
			clearCredentials: () =>
				set(() => ({
					accessToken: null,
					refreshToken: null,
					isLoggedIn: false,
					userId: null,
				})),
		}),
		{
			name: "dailysale-shop-auth",
		}
	)
);

export default useAuth;
