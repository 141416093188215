import useAxiosAuth from "@/hooks/useAxiosAuth";
import { MerchandisedDepartment } from "@/types";
import { DSS_ENDPOINTS } from "../dss-endpoints";

export const useDepartmentsApi = () => {
  const axios = useAxiosAuth();

  const fetchMerchandisedDepartments = async () => {
    try {
      const { data } = await axios.axiosNoAuth.get<MerchandisedDepartment[]>(
        DSS_ENDPOINTS.departments,
        {
          params: {
            display_only: "True",
          },
        }
      );
      return data;
    } catch (error) {
      throw new Error("Failed to fetch merchandised departments");
    }
  };

  const fetchMerchandisedDepartmentsCategories = () => {};

  return {
    fetchMerchandisedDepartments,
    fetchMerchandisedDepartmentsCategories,
  };
};
