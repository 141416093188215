import { create } from "zustand";
import { persist } from "zustand/middleware";

interface GlobalProps {
	showCategories: boolean;
	isLocal: boolean;
	toggleCategories: () => void;
	toggleIsLocal: () => void;
	setIsLocal: (local: boolean) => void;
	settings: any;
	setSettings: any;
	// -------- //
	autoScroll: boolean;
	setAutoScroll: (val: boolean) => void;
	openInNewTab: boolean;
	setOpenInNewTab: (val: boolean) => void;
	showGrid: boolean;
	setShowGrid: (val: boolean) => void;
}

const useGlobal = create<GlobalProps>()(
	persist(
		(set) => ({
			showCategories: false,
			isLocal: true,
			settings: null,
			toggleCategories: () =>
				set((state) => ({ showCategories: !state.showCategories })),
			toggleIsLocal: () => set((state) => ({ isLocal: !state.isLocal })),
			setIsLocal: (local) =>
				set(() => ({
					isLocal: local,
				})),
			setSettings: (data) =>
				set(() => ({
					settings: data,
				})),
			autoScroll: true,
			setAutoScroll: (val) =>
				set(() => ({
					autoScroll: val,
				})),
			openInNewTab: true,
			setOpenInNewTab: (val) =>
				set(() => ({
					openInNewTab: val,
				})),
			showGrid: true,
			setShowGrid: (val) =>
				set(() => ({
					showGrid: val,
				})),
		}),
		{
			name: "daily-sale-shop-global",
		}
	)
);

export default useGlobal;
