// import React, { Suspense, lazy } from "react";
import React from "react";
import {
	createBrowserRouter,
	Navigate,
	// Outlet,
	RouteObject,
} from "react-router-dom";
import useAuth from "@/hooks/useAuth";
import StoreLayout from "@/components/layouts/store-layout";

// import AccountPage from "@/pages/account";
// import { LoginPage, RegisterPage } from "@/pages/auth";
// import CartPage from "@/pages/cart";
// import HomePage from "@/pages/home";
// import homeRouteConfig from "@/pages/home/home-route-config";
// import ProductDetailsPage from "@/pages/product-details";
// import TrackingPage from "@/pages/tracking";
// import CheckoutPage from "@/pages/checkout";
// import NotFoundPage from "@/pages/not-found";

// import AboutUsPage from "@/pages/content/about-us";
// import PrivacyPolicyPage from "@/pages/content/privacy-policy";
// import TermsAndConditionsPage from "@/pages/content/terms-and-conditions";
// import PaymentPolicyPage from "@/pages/content/payment-policy";
// import WarrantyPolicyPage from "@/pages/content/warranty-policy";
// import RefundPolicyPage from "@/pages/content/refund-policy";
// import ContactUsPage from "@/pages/content/contact-us";
// import WishListPage from "@/pages/wishlist";
import AuthRoute from "./AuthRoute";

// Error Boundary Component
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// console.log(error, errorInfo);
	}

	render() {
		// @ts-ignore
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="flex h-screen w-full justify-center items-center">
					<h1>Something went wrong.</h1>
				</div>
			);
		}
		// @ts-ignore
		return this.props.children;
	}
}

// // ProtectedRoute Component
// const ProtectedRoute = ({ children }) => {
// 	const { isLoggedIn } = useAuth(); // assuming useAuth is correctly implemented and imported
// 	return isLoggedIn ? children : <Navigate to="/auth/login" replace />;
// };

// // GuestOnlyRoute Component
// const GuestOnlyRoute = ({ children }) => {
// 	const { isLoggedIn } = useAuth(); // assuming useAuth is properly implemented and imported
// 	return !isLoggedIn ? children : <Navigate to="/account" replace />;
// };

// const StoreLayout = lazy(() => import("@/components/layouts/store-layout"));
// const AccountPage = lazy(() => import("@/pages/account"));
// const LoginPage = lazy(() => import("@/pages/auth/login"));
// const RegisterPage = lazy(() => import("@/pages/auth/register"));
// const CartPage = lazy(() => import("@/pages/cart"));
// const HomePage = lazy(() => import("@/pages/home"));
// // const homeRouteConfig = lazy(() => import("@/pages/home/home-route-config"));
// const ProductDetailsPage = lazy(() => import("@/pages/product-details"));
// // const TrackingPage = lazy(() => import("@/pages/tracking"));
// const CheckoutPage = lazy(() => import("@/pages/checkout"));
// const NotFoundPage = lazy(() => import("@/pages/not-found"));

export const router = createBrowserRouter([
	{
		path: "/",
		element: <StoreLayout />,
		errorElement: <ErrorBoundary />,
		children: [
			// homeRouteConfig,
			{
				index: true,
				// element: <HomePage />,
				element: (
					<AuthRoute guestOnly lazy={() => import("../pages/home")} />
				),
			},
			{
				path: "featured/:featured_type",
				// element: <HomePage />,
				element: (
					<AuthRoute guestOnly lazy={() => import("../pages/home")} />
				),
			},
			{
				path: "search/:search_query",
				// element: <HomePage />,
				element: (
					<AuthRoute guestOnly lazy={() => import("../pages/home")} />
				),
			},
			{
				path: "categories/:department/:category",
				// element: <HomePage />,
				element: (
					<AuthRoute guestOnly lazy={() => import("../pages/home")} />
				),
			},

			{
				path: "products/:sku",
				// element: <ProductDetailsPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/product-details")}
					/>
				),
			},
			// {
			// 	path: "tracking/:tracking_id?",
			// 	element: (
			// 		<ProtectedRoute>
			// 			<TrackingPage />
			// 		</ProtectedRoute>
			// 	),
			// },
			{
				path: "cart",
				// element: <CartPage />,
				element: (
					<AuthRoute guestOnly lazy={() => import("../pages/cart")} />
				),
			},
			{
				path: "checkout",
				// element: <CheckoutPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/checkout")}
					/>
				),
			},
			{
				path: "wishlist",
				// element: <WishListPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/wishlist")}
					/>
				),
			},
			// {
			// 	path: "auth/login",
			// 	element: (
			// 		<GuestOnlyRoute>
			// 			<LoginPage />
			// 		</GuestOnlyRoute>
			// 	),
			// },
			// {
			// 	path: "auth/register",
			// 	element: (
			// 		<GuestOnlyRoute>
			// 			<RegisterPage />
			// 		</GuestOnlyRoute>
			// 	),
			// },
			// {
			// 	path: "account/:page?",
			// 	element: (
			// 		<ProtectedRoute>
			// 			<AccountPage />
			// 		</ProtectedRoute>
			// 	),
			// },
			{
				path: ":page/:filter",
				// element: <HomePage />,
				element: (
					<AuthRoute guestOnly lazy={() => import("../pages/home")} />
				),
			},
			{
				path: "/about-us",
				// element: <AboutUsPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/content/about-us")}
					/>
				),
			},
			{
				path: "/contact-us",
				// element: <ContactUsPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/content/contact-us")}
					/>
				),
			},
			{
				path: "/privacy-policy",
				// element: <PrivacyPolicyPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/content/privacy-policy")}
					/>
				),
			},
			{
				path: "/terms-and-conditions",
				// element: <TermsAndConditionsPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() =>
							import("../pages/content/terms-and-conditions")
						}
					/>
				),
			},
			{
				path: "/payment-policy",
				// element: <PaymentPolicyPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/content/payment-policy")}
					/>
				),
			},
			{
				path: "/warranty-policy",
				// element: <WarrantyPolicyPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/content/warranty-policy")}
					/>
				),
			},
			{
				path: "/refund-policy",
				// element: <RefundPolicyPage />,
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/content/refund-policy")}
					/>
				),
			},
			{
				path: "/help",
				// element: <RefundPolicyPage />,
				element: (
					<AuthRoute guestOnly lazy={() => import("../pages/help")} />
				),
			},
			{
				path: "*", // This is the fallback route
				// element: <NotFoundPage />, // Replace NotFoundPage with your component for not found routes
				element: (
					<AuthRoute
						guestOnly
						lazy={() => import("../pages/not-found")}
					/>
				),
			},
		],
	} as RouteObject,
]);
